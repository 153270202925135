import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'
import { PagefindUI } from '@pagefind/default-ui'

export default class extends Controller {
  connect() {
    useClickOutside(this)

    new PagefindUI({
      element: this.element,
      resetStyles: false,
      showSubResults: true,
      translations: { placeholder: 'Search blog posts' }
    })

    const form = this.element.querySelector('.pagefind-ui__form')
    const input = this.element.querySelector('.pagefind-ui__search-input')
    this.drawer = this.element.querySelector('.pagefind-ui__drawer')

    input.addEventListener('keypress', () => {
      if (input.value.length > 0) this.drawer.classList.remove('hidden')
    })

    form.addEventListener('focusin', () => {
      if (input.value.length > 0) this.drawer.classList.remove('hidden')
    })

    form.addEventListener('focusout', (event) => {
      if (form.contains(event.target)) return

      this.#deactivateInput()
    })
  }

  clickOutside() { this.#deactivateInput() }

  #deactivateInput() {
    this.drawer.classList.add('hidden')
  }
}
